.data-text {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.data-text2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter__item-value--green,
.counter__item-value--pink,
.counter__item-value--blue {
  margin-right: 10px;
}

.fa-linkedin {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form__title {
  color: white !important;
}

.logoes div img {
  height: 75px;
}
.select {
  color: white;
}

.lng {
  cursor: pointer;
}

.aboutme-ar {
  padding-left: 0px !important;
  padding-right: 50px !important;
  text-align: right !important;
}
.aboutme-ar::after {
  right: 0 !important;
}

.text-products {
  text-align: right !important;
}
.socials{
  z-index: 100;
}

#about-me-ar{
  margin-top: 1.5rem;
}

.alert{
  margin: 10px 0px;
  color: white;
}
.social-list__link{
  display: flex;
  justify-content: center;
}